import { createContext } from 'react'
import { VariantState } from './Variant.types'

const initialState: VariantState = {
  variant: null,
  updateVariant: () => null,
}

const VariantContext = createContext<VariantState>(initialState)

export default VariantContext
