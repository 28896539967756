import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import VariantContext from './Variant.context'
import VariantReducer from './Variant.reducer'
import { UPDATE_LANGUAGE, VariantState } from './Variant.types'

interface Props {
  children?: React.ReactNode
}

const VariantProvider: React.FC<Props> = ({ children }) => {
  let initialState: VariantState = {
    variant: null,
    updateVariant: () => null,
  }

  const [state, dispatch] = useReducer(VariantReducer, initialState)

  const updateVariant = (variant: string | null) => {
    dispatch({ type: UPDATE_LANGUAGE, payload: variant })
  }
  initialState = {
    ...initialState,
    updateVariant,
  }
  return (
    <VariantContext.Provider
      value={{
        variant: state.variant,
        updateVariant,
      }}
    >
      {children}
    </VariantContext.Provider>
  )
}

VariantProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default VariantProvider
