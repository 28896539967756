import {
  UPDATE_LANGUAGE,
  VariantAction,
  VariantState,
} from './Variant.types'

export default (
  state: VariantState,
  action: VariantAction
): VariantState => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        variant: action.payload,
      }
    default:
      return state
  }
}
